import { useQuery, useMutation } from '@finch-graphql/react';
import gql from 'graphql-tag';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AccountCreditRewardInfo = RewardInfo & {
  __typename?: 'AccountCreditRewardInfo';
  rewardType: Scalars['String'];
  metadata: AccountCreditRewardMetadata;
};

export type AccountCreditRewardMetadata = {
  __typename?: 'AccountCreditRewardMetadata';
  amount: Scalars['Int'];
  currencyCode: Scalars['String'];
};

export type AdditionalInfo = {
  __typename?: 'AdditionalInfo';
  isRemoved: Scalars['Boolean'];
  isKnown: Scalars['Boolean'];
  wordList?: Maybe<WordListType>;
  pronunciation?: Maybe<Scalars['String']>;
  definition?: Maybe<Scalars['String']>;
  isFluent: Scalars['Boolean'];
  progressionScore: Scalars['Int'];
};

export type AudioPreferences = {
  __typename?: 'AudioPreferences';
  playbackRate?: Maybe<Scalars['Float']>;
  voiceNumber?: Maybe<Scalars['Int']>;
};

export type AudioPreferencesInput = {
  playbackRate: Scalars['Float'];
  voiceNumber: Scalars['Int'];
};

export type Auth = {
  __typename?: 'Auth';
  token?: Maybe<Scalars['String']>;
};

export type AuthenticateUserInput = {
  token?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
};

export enum AuthFlow {
  LoginFlow = 'LoginFlow',
  SignUpFlow = 'SignUpFlow',
}

export type BadgeImage = {
  __typename?: 'BadgeImage';
  badgeImageUrl: Scalars['String'];
  badgeImageAltText: Scalars['String'];
};

export type Browser = {
  __typename?: 'Browser';
  permissions: Scalars['Boolean'];
  manifest?: Maybe<Manifest>;
  tab: Tab;
  activeTab: Tab;
  keyboardShortcuts: Array<BrowserCommands>;
};

export type BrowserPermissionsArgs = {
  permissions: PermissionsInput;
};

export type BrowserTabArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type BrowserCommands = {
  __typename?: 'BrowserCommands';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortcut?: Maybe<Scalars['String']>;
};

export type CallOuts = {
  __typename?: 'CallOuts';
  allCallOuts: Array<OnboardingItem>;
  currentCallout?: Maybe<OnboardingItem>;
};

export type Checklist = {
  __typename?: 'Checklist';
  amountCompleted: Scalars['Int'];
  checklistItems: Array<OnboardingItem>;
};

export enum ChecklistItemAll {
  HasCreatedToucanAccount = 'hasCreatedToucanAccount',
  HasHoveredOverTranslation = 'hasHoveredOverTranslation',
  HasPracticedFirstWord = 'hasPracticedFirstWord',
  HasSavedFirstWord = 'hasSavedFirstWord',
  HasLearnedAboutPremium = 'hasLearnedAboutPremium',
  HasGrantedPermissions = 'hasGrantedPermissions',
}

export enum ChecklistOnClick {
  OpenSubscriptionPage = 'OpenSubscriptionPage',
  RequestHostPermission = 'RequestHostPermission',
  OpenSidebarSignUp = 'OpenSidebarSignUp',
  ShowLottieAnimation = 'ShowLottieAnimation',
}

export enum ChecklistType {
  Language = 'Language',
  Permissions = 'Permissions',
  SignUp = 'SignUp',
  Hover = 'Hover',
  Settings = 'Settings',
  Practice = 'Practice',
}

export enum ComingSoonLanguageId {
  Nl = 'nl',
  En = 'en',
  Fa = 'fa',
  El = 'el',
  Ga = 'ga',
  Pl = 'pl',
  Ru = 'ru',
  Sv = 'sv',
  Tr = 'tr',
  Vi = 'vi',
  Zu = 'zu',
  Ar = 'ar',
  Zh = 'zh',
  He = 'he',
  Hi = 'hi',
  Other = 'other',
}

export type ContextlessLearning = ContextlessLearningMultipleChoice | ContextlessLearningFlashCard;

export type ContextlessLearningFlashCard = {
  __typename?: 'ContextlessLearningFlashCard';
  learningType: ContextlessLearningType;
  sourcePhrase: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
};

export type ContextlessLearningMoment = {
  __typename?: 'ContextlessLearningMoment';
  learnings: Array<ContextlessLearning>;
  translationIds: Array<Scalars['String']>;
};

export type ContextlessLearningMultipleChoice = {
  __typename?: 'ContextlessLearningMultipleChoice';
  learningType: ContextlessLearningType;
  targetPhrase: Scalars['String'];
  sourcePhraseAnswer: Scalars['String'];
  sourceRoot: Scalars['String'];
  sourcePrefix?: Maybe<Scalars['String']>;
  sourceSuffix?: Maybe<Scalars['String']>;
  sourcePhraseOptions: Array<Scalars['String']>;
};

export enum ContextlessLearningType {
  MultipleChoice = 'MultipleChoice',
  FlashCard = 'FlashCard',
}

export type CurrentFocus = {
  __typename?: 'CurrentFocus';
  name?: Maybe<Scalars['String']>;
  learningShortcutId?: Maybe<Scalars['String']>;
};

export type Dictionary = {
  __typename?: 'Dictionary';
  language: Scalars['String'];
  /** @deprecated This is the old translation dataset structure. No API has replaced it, but this was not being used */
  words: Array<Word>;
  hasPronunciations?: Maybe<Scalars['Boolean']>;
  size: Scalars['Int'];
  isSameLanguage: Scalars['Boolean'];
};

export type DifficultyWeight = {
  __typename?: 'DifficultyWeight';
  tier: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
};

export enum DisplayType {
  Example = 'Example',
  Question = 'Question',
  Confirm = 'Confirm',
}

export type Domain = {
  __typename?: 'Domain';
  domain: Scalars['String'];
  isBanned: Scalars['Boolean'];
  showReminder: Scalars['Boolean'];
  hasReminder: Scalars['Boolean'];
  unbannable: Scalars['Boolean'];
  injectionAmount: Scalars['Int'];
  sawPermissionUpsell: Scalars['Boolean'];
};

export type EmailShareInfo = {
  __typename?: 'EmailShareInfo';
  to: Scalars['String'];
  hasBeenRedeemed: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type ExpandedInlineTranslation = {
  __typename?: 'ExpandedInlineTranslation';
  expandedSourcePhrase?: Maybe<Scalars['String']>;
  targetPhrase?: Maybe<Scalars['String']>;
};

export type ExpandInlineTranslationInput = {
  sourcePhrase: Scalars['String'];
  sourcePhraseInstanceIndex: Scalars['Int'];
  sourceSentence: Scalars['String'];
  variationId: Scalars['String'];
};

export type Experiment = {
  __typename?: 'Experiment';
  accepted: Scalars['Boolean'];
  experimentName: Scalars['String'];
};

export type ExperimentTest = {
  __typename?: 'ExperimentTest';
  experiment: Scalars['String'];
  variation: Scalars['String'];
};

export enum ExtraPracticeMomentType {
  ExampleSentencePracticeMoment = 'ExampleSentencePracticeMoment',
}

export enum ExtraPracticeQuestionType {
  SourcePhraseMultipleChoiceExtraPracticeQuestion = 'SourcePhraseMultipleChoiceExtraPracticeQuestion',
  TargetPhraseMultipleChoiceExtraPracticeQuestion = 'TargetPhraseMultipleChoiceExtraPracticeQuestion',
  ListenExtraPracticeQuestion = 'ListenExtraPracticeQuestion',
}

export type Feature = {
  __typename?: 'Feature';
  enabled: Scalars['Boolean'];
};

export type Feed = {
  __typename?: 'Feed';
  cta?: Maybe<Scalars['String']>;
  ctaURL?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  layout: Scalars['String'];
  title: Scalars['String'];
  addedAt?: Maybe<Scalars['String']>;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type HeroImage = {
  __typename?: 'HeroImage';
  heroImageUrl: Scalars['String'];
  heroImageAltText: Scalars['String'];
};

export type HighlightToTranslateInfo = {
  __typename?: 'HighlightToTranslateInfo';
  usageAmount: Scalars['Int'];
  translation?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  overlimit: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  showWarning: Scalars['Boolean'];
  showOnboarding: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  freeUsesRemaining: Scalars['Int'];
  containedPhrases: Array<Maybe<HttContainedPhrase>>;
};

export type HighlightToTranslateInput = {
  translationIds: Array<Scalars['String']>;
};

export type HighlightToTranslateScreen = {
  __typename?: 'HighlightToTranslateScreen';
  translations: Array<ProgressionTranslation>;
};

export type Homonym = {
  __typename?: 'Homonym';
  homonymId: Scalars['String'];
  sourcePhrase?: Maybe<Scalars['String']>;
  targetPhrase?: Maybe<Scalars['String']>;
  progressionScore: Scalars['Int'];
  possibleProgressionScore: Scalars['Int'];
};

export type HomonymProgressionUpdate = {
  __typename?: 'HomonymProgressionUpdate';
  progressionScore: Scalars['Int'];
  nextLearningShortcutTopic?: Maybe<Scalars['String']>;
  hasCompletedShortcut: Scalars['Boolean'];
  hasCelebrated: Scalars['Boolean'];
  celebrationTitle?: Maybe<Scalars['String']>;
  subject: LearningShortcutSubject;
};

export type HttContainedPhrase = {
  __typename?: 'HTTContainedPhrase';
  translationId: Scalars['String'];
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export type HydratedTranslationReplacement = {
  __typename?: 'HydratedTranslationReplacement';
  id: Scalars['String'];
  replacement: TranslationReplacement;
  translation: TranslationData;
  source?: Maybe<Scalars['String']>;
  targetAddress: Scalars['String'];
  densityThreshold: Scalars['Float'];
};

export type InjectActiveTabInput = {
  tabId?: Maybe<Scalars['Int']>;
};

export type InlineTranslationLearningShortcutInfo = {
  __typename?: 'InlineTranslationLearningShortcutInfo';
  title: Scalars['String'];
  percentCompleted: Scalars['Int'];
  badgeImage: BadgeImage;
};

export type Language = {
  __typename?: 'Language';
  language: Scalars['String'];
  languageId: Scalars['String'];
  flagEmoji: Scalars['String'];
};

export type LearningShortcutDetailsScreen = {
  __typename?: 'LearningShortcutDetailsScreen';
  name: Scalars['String'];
  heroImage: HeroImage;
  title: Scalars['String'];
  details: Scalars['String'];
  shortcutId: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  learningShortcutTopics: Array<LearningShortcutTopic>;
};

export type LearningShortcutHomonym = {
  __typename?: 'LearningShortcutHomonym';
  homonymId: Scalars['String'];
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
  progressionScore: Scalars['Int'];
};

export type LearningShortcutItem = {
  __typename?: 'LearningShortcutItem';
  title: Scalars['String'];
  percentCompleted: Scalars['Int'];
  currentFocus: CurrentFocus;
  heroImage: HeroImage;
  badgeImage: BadgeImage;
  numberOfWordsLearnedToday: Scalars['Int'];
  whatYouKnow: Array<Maybe<WhatYouKnow>>;
  learningShortcutId: Scalars['String'];
  completedLearningShortcutPhraseCount: Scalars['Int'];
  possibleLearningShortcutPhraseCount: Scalars['Int'];
};

export type LearningShortcutsOverviewScreen = {
  __typename?: 'LearningShortcutsOverviewScreen';
  learningShortcutItem?: Maybe<LearningShortcutItem>;
  moreLearningShortcuts: Array<MoreLearningShortcuts>;
};

export enum LearningShortcutSubject {
  Food = 'food',
  Friends = 'friends',
  Greetings = 'greetings',
  Survival = 'survival',
}

export type LearningShortcutTopic = {
  __typename?: 'LearningShortcutTopic';
  name: Scalars['String'];
  topicId: Scalars['String'];
  completedTopicPhraseCount: Scalars['Int'];
  possibleTopicPhraseCount: Scalars['Int'];
};

export type LearningShortcutWordListScreen = {
  __typename?: 'LearningShortcutWordListScreen';
  name: Scalars['String'];
  completedTopicPhraseCount: Scalars['Int'];
  possibleTopicPhraseCount: Scalars['Int'];
  wordList: Array<LearningShortcutHomonym>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Manifest = {
  __typename?: 'Manifest';
  name: Scalars['String'];
  version: Scalars['String'];
  description: Scalars['String'];
};

export type MatchQuestionInfo = {
  __typename?: 'MatchQuestionInfo';
  questionType: PracticeType;
  matches: Array<MatchQuestionOption>;
  translationIds: Array<Scalars['String']>;
  displayType?: Maybe<DisplayType>;
  sentence?: Maybe<Array<Sentence>>;
  answer?: Maybe<Scalars['String']>;
  displayPhrase?: Maybe<Scalars['String']>;
};

export type MatchQuestionOption = {
  __typename?: 'MatchQuestionOption';
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
};

export enum MatchStatus {
  PrefixCollocation = 'PrefixCollocation',
  SuffixCollocation = 'SuffixCollocation',
  PartOfSpeechPhraseMatch = 'PartOfSpeechPhraseMatch',
  PartOfSpeechMatch = 'PartOfSpeechMatch',
  CommonMatch = 'CommonMatch',
  ReversePhraseMatch = 'ReversePhraseMatch',
  NoMatch = 'NoMatch',
  NotTranslatable = 'NotTranslatable',
  Skipped = 'Skipped',
}

export type Me = {
  __typename?: 'Me';
  appleReceipt?: Maybe<Scalars['String']>;
  audioPreferences?: Maybe<AudioPreferences>;
  countryCode: Scalars['String'];
  difficultyTier?: Maybe<Scalars['String']>;
  difficultyWeights?: Maybe<Array<DifficultyWeight>>;
  email?: Maybe<Scalars['String']>;
  /** @deprecated We use feature flagging for this experimentation */
  experiments?: Maybe<Array<Experiment>>;
  id: Scalars['String'];
  isAlwaysTranslateSavedWordsEnabled: Scalars['Boolean'];
  isContextlessLearningMomentEnabled: Scalars['Boolean'];
  isDarkModeEnabled: Scalars['Boolean'];
  isPremiumSubscribed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  notificationFeed: Array<Feed>;
  seenTranslations: Array<Scalars['String']>;
  selectedLanguage: Scalars['String'];
  sourceLanguage: Scalars['String'];
  translation?: Maybe<UserTranslation>;
  userReferral: UserReferral;
  websiteUser?: Maybe<WebsiteUser>;
  wordCount: Scalars['Int'];
};

export type MeSeenTranslationsArgs = {
  amount: Scalars['Int'];
};

export type MeTranslationArgs = {
  id: Scalars['String'];
  input?: Maybe<UserTranslationInput>;
};

export type MoreLearningShortcuts = {
  __typename?: 'MoreLearningShortcuts';
  name: Scalars['String'];
  percentCompleted: Scalars['Int'];
  tileImage: TileImage;
  learningShortcutId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateLearningShortcut?: Maybe<Scalars['Boolean']>;
  addDomainToBanlist: Settings;
  addRating: Scalars['Boolean'];
  addReminderTime: Settings;
  addToWordList: WordTranslation;
  addTranslationInaccuracies: Scalars['Boolean'];
  authenticateUser: Scalars['Boolean'];
  changeAnalyticsTrackingPreference?: Maybe<Scalars['Boolean']>;
  changeDailyGoalsEnabledState: Settings;
  changeDensityLevel: Settings;
  changeGloballyDisabledDuration: Settings;
  changeGloballyEnabledState: Settings;
  changeHighlightToTranslatedEnabledState: Settings;
  changeLanguage?: Maybe<Scalars['Boolean']>;
  changeNewNotificationState?: Maybe<Scalars['Boolean']>;
  changePronunciationEnabledState: Settings;
  clearTabTranslationCounts: Scalars['Boolean'];
  clearWordCache: Word;
  closeSettings: Scalars['Boolean'];
  createTab: Scalars['Boolean'];
  domainUpsellSeen: Settings;
  editShortcuts?: Maybe<Scalars['Boolean']>;
  enableKeyboardShortcuts?: Maybe<Scalars['Boolean']>;
  expandInlineTranslation: ExpandedInlineTranslation;
  forceDatasetDownload: Scalars['Boolean'];
  forceOnboardingDatasetDownload: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  highlightToTranslateOnboarding?: Maybe<Scalars['ID']>;
  incrementPossibleTranslations: Scalars['Boolean'];
  incrementTranslationViewed: Scalars['Boolean'];
  incrementWebPageVisit: Scalars['Boolean'];
  injectActiveTab: Scalars['Boolean'];
  injectSidebarIntoTab: Scalars['Boolean'];
  invalidateTranslations: Scalars['Boolean'];
  logInAsWebsiteUser: Me;
  loginUser: Me;
  logoutUserOnExtension: Scalars['Boolean'];
  markHasLearnedAboutPremiumAsSeen?: Maybe<Scalars['Boolean']>;
  markSettingsOpened: Scalars['Boolean'];
  markWordAsFluent: Scalars['Boolean'];
  markWordAsKnown: WordTranslation;
  markWordAsRemoved: Scalars['Boolean'];
  markWordAsUnknown: WordTranslation;
  openSettings: Scalars['Boolean'];
  performPostSubscriptionActions?: Maybe<Scalars['Boolean']>;
  recordContextlessLearningMomentQuizResults?: Maybe<Scalars['Boolean']>;
  recordQuizProgression?: Maybe<Scalars['Boolean']>;
  recordTranslationAudioPlay: WordTranslation;
  recordViewsForTranslation: WordTranslation;
  reloadActiveTab: Scalars['Boolean'];
  removeContextlessLearningMomentCache: Scalars['Boolean'];
  removeDomainFromBanlist: Settings;
  removeExtraPracticeCache: Scalars['Boolean'];
  removeFromWordList: WordTranslation;
  removeReminder: Settings;
  requestAccountDeletion: Scalars['Boolean'];
  requestAuthorization: Auth;
  requestPermission: Browser;
  requestSafariInAppPayments: Scalars['Boolean'];
  saveLearningShortcutCelebration: Scalars['Boolean'];
  sendErrorReportEmail: Scalars['Boolean'];
  sendReferralEmails?: Maybe<UserReferral>;
  setAlwaysTranslate: Scalars['Boolean'];
  setComingSoonLanguage: Settings;
  setCookie: Scalars['Boolean'];
  setLastSeenProgressionScore: TranslationProgression;
  setUserAudioPreferences: Scalars['Boolean'];
  shareToFacebook?: Maybe<Scalars['Boolean']>;
  shareToTwitter?: Maybe<Scalars['Boolean']>;
  signupUser: Me;
  toggleContextlessLearningMoment?: Maybe<Scalars['Boolean']>;
  toggleDarkMode?: Maybe<Scalars['Boolean']>;
  traceSpans: Scalars['Boolean'];
  trackExperiment: Scalars['Boolean'];
  translateMapping?: Maybe<Array<TranslatedMappingResp>>;
  translateSelection: HighlightToTranslateInfo;
  uninstallExtension: Scalars['Boolean'];
  updateDifficultyTier?: Maybe<Scalars['Boolean']>;
  updatePracticeQuizItem?: Maybe<Scalars['Boolean']>;
  updateRecordHomonymProgression: HomonymProgressionUpdate;
  updateShortcutEmoji: Scalars['Boolean'];
  welcomeUser: Scalars['Boolean'];
};

export type MutationActivateLearningShortcutArgs = {
  input: UserLearningShortcutInput;
};

export type MutationAddDomainToBanlistArgs = {
  domain: Scalars['String'];
};

export type MutationAddRatingArgs = {
  rating: RatingInput;
};

export type MutationAddReminderTimeArgs = {
  reminder: ReminderInput;
};

export type MutationAddToWordListArgs = {
  addition: WordListInput;
};

export type MutationAddTranslationInaccuraciesArgs = {
  information: TranslationInaccuraciesInput;
};

export type MutationAuthenticateUserArgs = {
  input: AuthenticateUserInput;
};

export type MutationChangeAnalyticsTrackingPreferenceArgs = {
  isOptedIn: Scalars['Boolean'];
};

export type MutationChangeDailyGoalsEnabledStateArgs = {
  enabled: Scalars['Boolean'];
};

export type MutationChangeDensityLevelArgs = {
  level: Scalars['Int'];
};

export type MutationChangeGloballyDisabledDurationArgs = {
  duration: SnoozeDuration;
};

export type MutationChangeGloballyEnabledStateArgs = {
  enabled: Scalars['Boolean'];
};

export type MutationChangeHighlightToTranslatedEnabledStateArgs = {
  enabled: Scalars['Boolean'];
};

export type MutationChangeLanguageArgs = {
  sourceLanguage: Scalars['String'];
  targetLanguage: Scalars['String'];
};

export type MutationChangeNewNotificationStateArgs = {
  updated: Scalars['Boolean'];
};

export type MutationChangePronunciationEnabledStateArgs = {
  enabled: Scalars['Boolean'];
};

export type MutationClearWordCacheArgs = {
  word: Scalars['String'];
};

export type MutationCreateTabArgs = {
  url: Scalars['String'];
};

export type MutationDomainUpsellSeenArgs = {
  domain: Scalars['String'];
};

export type MutationEnableKeyboardShortcutsArgs = {
  enabled: Scalars['Boolean'];
};

export type MutationExpandInlineTranslationArgs = {
  input: ExpandInlineTranslationInput;
};

export type MutationForgotPasswordArgs = {
  auth: ForgotPasswordInput;
};

export type MutationIncrementPossibleTranslationsArgs = {
  amount: Scalars['Int'];
};

export type MutationIncrementTranslationViewedArgs = {
  translationId: Scalars['String'];
};

export type MutationIncrementWebPageVisitArgs = {
  input: WebPageVisitInput;
};

export type MutationInjectActiveTabArgs = {
  input?: Maybe<InjectActiveTabInput>;
};

export type MutationInvalidateTranslationsArgs = {
  translations?: Maybe<Array<Scalars['String']>>;
};

export type MutationLoginUserArgs = {
  auth: LoginInput;
};

export type MutationLogoutUserOnExtensionArgs = {
  fromExtension?: Maybe<Scalars['Boolean']>;
};

export type MutationMarkWordAsFluentArgs = {
  id: Scalars['String'];
};

export type MutationMarkWordAsKnownArgs = {
  id: Scalars['String'];
};

export type MutationMarkWordAsRemovedArgs = {
  id: Scalars['String'];
};

export type MutationMarkWordAsUnknownArgs = {
  id: Scalars['String'];
};

export type MutationOpenSettingsArgs = {
  panelName?: Maybe<Scalars['String']>;
  overlayName?: Maybe<Scalars['String']>;
  slideName?: Maybe<Scalars['String']>;
  tabId?: Maybe<Scalars['Int']>;
  hydration?: Maybe<Scalars['String']>;
};

export type MutationRecordContextlessLearningMomentQuizResultsArgs = {
  input: RecordContextlessLearningMomentQuizResultsInput;
};

export type MutationRecordQuizProgressionArgs = {
  input: RecordQuizResultsInput;
};

export type MutationRecordTranslationAudioPlayArgs = {
  id: Scalars['String'];
};

export type MutationRecordViewsForTranslationArgs = {
  id: Scalars['String'];
};

export type MutationRemoveDomainFromBanlistArgs = {
  domain: Scalars['String'];
};

export type MutationRemoveFromWordListArgs = {
  removal: WordListInput;
};

export type MutationRemoveReminderArgs = {
  domain: Scalars['String'];
};

export type MutationRequestAuthorizationArgs = {
  key?: Maybe<Scalars['String']>;
};

export type MutationRequestPermissionArgs = {
  permissions: PermissionsInput;
};

export type MutationRequestSafariInAppPaymentsArgs = {
  input: RequestSafariInAppPaymentsInput;
};

export type MutationSaveLearningShortcutCelebrationArgs = {
  input: UserLearningShortcutCelebrationInput;
};

export type MutationSendErrorReportEmailArgs = {
  translation: Scalars['String'];
  errorReason: Scalars['String'];
  errorDetails?: Maybe<Scalars['String']>;
  translationId: Scalars['String'];
};

export type MutationSendReferralEmailsArgs = {
  emails: Array<Scalars['String']>;
};

export type MutationSetAlwaysTranslateArgs = {
  alwaysTranslate: Scalars['Boolean'];
};

export type MutationSetComingSoonLanguageArgs = {
  languageId?: Maybe<ComingSoonLanguageId>;
};

export type MutationSetCookieArgs = {
  name: Scalars['String'];
  value: Scalars['String'];
  numDaysActive?: Maybe<Scalars['Int']>;
};

export type MutationSetLastSeenProgressionScoreArgs = {
  input: SetLastSeenProgressionScoreInput;
};

export type MutationSetUserAudioPreferencesArgs = {
  input: AudioPreferencesInput;
};

export type MutationShareToFacebookArgs = {
  share: Share;
};

export type MutationShareToTwitterArgs = {
  share: Share;
};

export type MutationSignupUserArgs = {
  auth: SignupInput;
};

export type MutationToggleContextlessLearningMomentArgs = {
  enabled: Scalars['Boolean'];
};

export type MutationToggleDarkModeArgs = {
  enabled: Scalars['Boolean'];
};

export type MutationTraceSpansArgs = {
  spans: Array<TelemetrySpan>;
};

export type MutationTrackExperimentArgs = {
  event: TrackExperimentInput;
};

export type MutationTranslateMappingArgs = {
  mapping: TranslateMappingInput;
};

export type MutationTranslateSelectionArgs = {
  selection: TranslateSelectionInput;
};

export type MutationUpdateDifficultyTierArgs = {
  difficultyTier: Scalars['String'];
};

export type MutationUpdateRecordHomonymProgressionArgs = {
  input: Array<RecordHomonymProgressionInput>;
};

export type MutationUpdateShortcutEmojiArgs = {
  indicator: Scalars['String'];
};

export type MutationWelcomeUserArgs = {
  flow: AuthFlow;
};

export type Onboarding = {
  __typename?: 'Onboarding';
  callOuts: CallOuts;
  checklist: Checklist;
};

export type OnboardingItem = {
  __typename?: 'OnboardingItem';
  itemType?: Maybe<ChecklistType>;
  item?: Maybe<ChecklistItemAll>;
  title?: Maybe<Scalars['String']>;
  isCompleted: Scalars['Boolean'];
  filePath?: Maybe<Scalars['String']>;
  onClickType?: Maybe<ChecklistOnClick>;
  hasCallout: Scalars['Boolean'];
  isShown?: Maybe<Scalars['Boolean']>;
};

export type Page = {
  __typename?: 'Page';
  hydratedTranslations: Array<HydratedTranslationReplacement>;
  url: Scalars['String'];
};

export type PermissionsInput = {
  origins?: Maybe<Array<Scalars['String']>>;
  permissions?: Maybe<Array<Scalars['String']>>;
};

export type Practice = {
  __typename?: 'Practice';
  /**
   * instead of using the ExtraPracticeQuestionType from the server, we'll use the
   * correspoding PracticeType from the background.
   * - SwapPracticeQuestion from PracticeType corresponds with
   * SourcePhraseMultipleChoiceExtraPracticeQuestion from ExtraPracticeQuestionType
   * - SourcePhraseSwapPracticeQuestion from PracticeType corresponds with
   * TargetPhraseMultipleChoiceExtraPracticeQuestion from ExtraPracticeQuestionType
   * - ListenPracticeQuestion from PracticeType corresponds with ListenExtraPracticeQuestion from ExtraPracticeQuestionType
   */
  displayType: DisplayType;
  questionType?: Maybe<PracticeType>;
  answer?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Scalars['String']>>;
  sentence?: Maybe<Array<Sentence>>;
  displayPhrase?: Maybe<Scalars['String']>;
};

export enum PracticeType {
  SwapPracticeQuestion = 'SwapPracticeQuestion',
  ListenPracticeQuestion = 'ListenPracticeQuestion',
  LatinSpellingPracticeQuestion = 'LatinSpellingPracticeQuestion',
  NonLatinSpellingPracticeQuestion = 'NonLatinSpellingPracticeQuestion',
  MatchPracticeQuestion = 'MatchPracticeQuestion',
  SourcePhraseSwapPracticeQuestion = 'SourcePhraseSwapPracticeQuestion',
}

export type ProgressionItem = {
  __typename?: 'ProgressionItem';
  type: ProgressionType;
  displayName: Scalars['String'];
  icon: Scalars['String'];
  iconColor: Scalars['String'];
  iconFillColor: Scalars['String'];
  amount: Scalars['Int'];
  targetPhrases: Array<Scalars['String']>;
};

export enum ProgressionPeriod {
  Today = 'Today',
  Yesterday = 'Yesterday',
}

export type ProgressionScreen = {
  __typename?: 'ProgressionScreen';
  topPercent: Scalars['Int'];
  progressionItems: Array<ProgressionItem>;
};

export type ProgressionTranslation = {
  __typename?: 'ProgressionTranslation';
  translationId: Scalars['String'];
  sourceLanguage: Scalars['String'];
  targetLanguage: Scalars['String'];
  targetPhrase: Scalars['String'];
  sourcePhrase: Scalars['String'];
  learnedAt?: Maybe<Scalars['String']>;
  fluentAt?: Maybe<Scalars['String']>;
  isKnown: Scalars['Boolean'];
  isSaved: Scalars['Boolean'];
  isFluent: Scalars['Boolean'];
  progressionScore: Scalars['Int'];
  viewCount?: Maybe<Scalars['Int']>;
  lastViewed?: Maybe<Scalars['Float']>;
  practicedToday?: Maybe<Scalars['Boolean']>;
  lastPracticedAt: Scalars['Int'];
  difficultyLevel?: Maybe<Scalars['String']>;
};

export enum ProgressionType {
  Viewed = 'Viewed',
  Learned = 'Learned',
}

export type Query = {
  __typename?: 'Query';
  activePremiumExperimentName: Scalars['String'];
  browser?: Maybe<Browser>;
  contextlessLearningMoment: ContextlessLearningMoment;
  currentPage?: Maybe<Page>;
  dictionary?: Maybe<Dictionary>;
  doesCurrentLanguageVectorSupportShortcuts: Scalars['Boolean'];
  experiments: Array<ExperimentTest>;
  feature: Feature;
  featuresReady?: Maybe<Scalars['Boolean']>;
  fetchTextToSpeech: Scalars['String'];
  getTranslationInaccuraciesFromLocal: Array<Scalars['String']>;
  highlightToTranslate: HighlightToTranslateInfo;
  inlineTranslationLearningShortcutInfo: InlineTranslationLearningShortcutInfo;
  me?: Maybe<Me>;
  onboarding: Onboarding;
  page?: Maybe<Page>;
  quiz?: Maybe<Quiz>;
  screens: Screens;
  settings: Settings;
  shortcutsExtraPractice?: Maybe<ShortcutsExtraPractice>;
  supportedLanguages: Array<Language>;
  translationId?: Maybe<Scalars['String']>;
  translationsSeen: TranslationsSeen;
  word?: Maybe<WordTranslation>;
  /** @deprecated Field no longer supported */
  words: Array<Word>;
};

export type QueryFeatureArgs = {
  name: Scalars['String'];
};

export type QueryFetchTextToSpeechArgs = {
  text: Scalars['String'];
  language: Scalars['String'];
  playbackRate?: Maybe<Scalars['Float']>;
  voiceNumber?: Maybe<Scalars['Int']>;
};

export type QueryMeArgs = {
  noCache?: Maybe<Scalars['Boolean']>;
};

export type QueryPageArgs = {
  url: Scalars['String'];
};

export type QueryQuizArgs = {
  translationIds: Array<Scalars['String']>;
  quizType: QuizType;
};

export type QueryTranslationIdArgs = {
  input: TranslationIdLookup;
};

export type QueryWordArgs = {
  id: Scalars['String'];
};

export type QuestionInfo = MatchQuestionInfo | SingleQuestionInfo;

export type Quiz = {
  __typename?: 'Quiz';
  questions: Array<QuestionInfo>;
};

export enum QuizType {
  Practice = 'Practice',
  MultiPractice = 'MultiPractice',
}

export type RatingInput = {
  ratingValue: Scalars['Int'];
};

export type RecordContextlessLearningMomentQuizResultsInput = {
  results: Array<Scalars['String']>;
  quizType: ContextlessLearningType;
  translationIds: Array<Scalars['String']>;
};

export type RecordHomonymProgressionInput = {
  homonymRecordId: Scalars['String'];
  progressionDifference: Scalars['Int'];
};

export type RecordQuizResultsInput = {
  quizType: QuizType;
  translationIds: Array<Scalars['String']>;
  quizStatus?: Maybe<Scalars['String']>;
  results: Array<Scalars['String']>;
};

export type ReminderInput = {
  domain: Scalars['String'];
  remind: SnoozeDuration;
};

export type RequestSafariInAppPaymentsInput = {
  completionRedirectUrl: Scalars['String'];
};

export type RewardInfo = {
  rewardType: Scalars['String'];
};

export type SavedWordListScreen = {
  __typename?: 'SavedWordListScreen';
  callout?: Maybe<SavedWordsCallout>;
  amountPracticed: Scalars['Int'];
  amount: Scalars['Int'];
  translations: Array<ProgressionTranslation>;
};

export type SavedWordListScreenInput = {
  sort?: Maybe<WordListSort>;
  highlightedTranslation?: Maybe<Scalars['String']>;
};

export type SavedWordsCallout = {
  __typename?: 'SavedWordsCallout';
  status: SavedWordsCalloutStatus;
  title: Scalars['String'];
  cta: Scalars['String'];
  icon: Scalars['String'];
};

export enum SavedWordsCalloutStatus {
  Practice = 'Practice',
  AllPracticed = 'AllPracticed',
  Locked = 'Locked',
  NotEnoughWords = 'NotEnoughWords',
}

export type SchoolDonationRewardInfo = RewardInfo & {
  __typename?: 'SchoolDonationRewardInfo';
  rewardType: Scalars['String'];
  metadata: SchoolDonationRewardMetadata;
};

export type SchoolDonationRewardMetadata = {
  __typename?: 'SchoolDonationRewardMetadata';
  schoolId: Scalars['String'];
  schoolName: Scalars['String'];
};

export type Screens = {
  __typename?: 'Screens';
  _?: Maybe<Scalars['Boolean']>;
  highlightToTranslateScreen: HighlightToTranslateScreen;
  learningShortcutDetails: LearningShortcutDetailsScreen;
  learningShortcutWordList: LearningShortcutWordListScreen;
  learningShortcutsOverview: LearningShortcutsOverviewScreen;
  progressionOverview: ProgressionScreen;
  savedWordList: SavedWordListScreen;
  wordList: WordListScreen;
};

export type ScreensHighlightToTranslateScreenArgs = {
  input: HighlightToTranslateInput;
};

export type ScreensLearningShortcutDetailsArgs = {
  input: UserLearningShortcutInput;
};

export type ScreensLearningShortcutWordListArgs = {
  input: UserLearningShortcutTopicInput;
};

export type ScreensProgressionOverviewArgs = {
  period: ProgressionPeriod;
};

export type ScreensSavedWordListArgs = {
  input: SavedWordListScreenInput;
};

export type ScreensWordListArgs = {
  input: WordListScreenInput;
};

export type Sentence = {
  __typename?: 'Sentence';
  type: SentenceType;
  text?: Maybe<Scalars['String']>;
};

export enum SentenceType {
  Text = 'Text',
  Translation = 'Translation',
}

export type SetLastSeenProgressionScoreInput = {
  id: Scalars['String'];
  lastSeenProgressionScore: Scalars['Int'];
};

export type Settings = {
  __typename?: 'Settings';
  densityLevel?: Maybe<Scalars['Int']>;
  isGloballyEnabled?: Maybe<Scalars['Boolean']>;
  isSnoozed?: Maybe<Scalars['Boolean']>;
  disabledDate?: Maybe<Scalars['String']>;
  disabledDuration?: Maybe<SnoozeDuration>;
  highlightToTranslateEnabled: Scalars['Boolean'];
  domain?: Maybe<Domain>;
  selectedComingSoonLanguageId?: Maybe<ComingSoonLanguageId>;
  isSnoozedForNewLanguage: Scalars['Boolean'];
  isPronunciationEnabled: Scalars['Boolean'];
  isDailyGoalsModalEnabled: Scalars['Boolean'];
  userBanlist: Array<Scalars['String']>;
  isKeyboardShortcutsEnabled: Scalars['Boolean'];
  isNewNotificationAvailable?: Maybe<Scalars['Boolean']>;
  isNotificationFeedbackShown: Scalars['Boolean'];
  isAllowingTrackingAnalytics: Scalars['Boolean'];
  notificationFeed: Array<Feed>;
};

export type SettingsDomainArgs = {
  domain: Scalars['String'];
};

export type Share = {
  url: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type ShortcutsExtraPractice = {
  __typename?: 'ShortcutsExtraPractice';
  title: Scalars['String'];
  badgeImage: BadgeImage;
  topicName: Scalars['String'];
  homonym: Homonym;
  practice: Array<Practice>;
};

export type SignupInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
};

export type SingleQuestionInfo = {
  __typename?: 'SingleQuestionInfo';
  questionType: PracticeType;
  displayPhrase: Scalars['String'];
  answer: Scalars['String'];
  options: Array<Scalars['String']>;
  translationId: Scalars['String'];
  displayType?: Maybe<DisplayType>;
  sentence?: Maybe<Array<Sentence>>;
};

export enum SnoozeDuration {
  FiveMinutes = 'FiveMinutes',
  OneHour = 'OneHour',
  Evening = 'Evening',
  Tomorrow = 'Tomorrow',
  DailyNineToSix = 'DailyNineToSix',
  Indefinitely = 'Indefinitely',
}

export type Tab = {
  __typename?: 'Tab';
  id: Scalars['Int'];
  windowType?: Maybe<Scalars['String']>;
  isToucanInjected: Scalars['Boolean'];
};

export type TelemetryAttribute = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TelemetrySpan = {
  startTime: Scalars['Float'];
  endTime: Scalars['Float'];
  name: Scalars['String'];
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  attributes?: Maybe<Array<TelemetryAttribute>>;
};

export type TileImage = {
  __typename?: 'TileImage';
  tileImageUrl: Scalars['String'];
  tileImageAltText: Scalars['String'];
};

export type TrackExperimentInput = {
  eventName: Scalars['String'];
  properties?: Maybe<Array<TrackingProperties>>;
};

export type TrackingProperties = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type TranslatedMappingResp = {
  __typename?: 'TranslatedMappingResp';
  id: Scalars['String'];
  replacement: TranslationReplacement;
  translation: TranslationData;
  source?: Maybe<Scalars['String']>;
  targetId: Scalars['String'];
  densityThreshold: Scalars['Float'];
};

export type TranslateMappingInput = {
  mapping: Array<Array<Scalars['String']>>;
  id: Scalars['String'];
  excludedSubNodes: Array<Scalars['String']>;
  mainAddress?: Maybe<Scalars['String']>;
};

export type TranslateSelectionInput = {
  text: Scalars['String'];
};

export type Translation = {
  __typename?: 'Translation';
  collocation?: Maybe<Scalars['String']>;
  translation: Scalars['String'];
};

export type TranslationData = {
  __typename?: 'TranslationData';
  sourcePhrase: Scalars['String'];
  partOfSpeech?: Maybe<Scalars['String']>;
  targetPhrase: Scalars['String'];
  translationId: Scalars['String'];
};

export type TranslationIdLookup = {
  sourcePhrase: Scalars['String'];
  targetPhrase: Scalars['String'];
  language: Scalars['String'];
};

export type TranslationInaccuraciesInput = {
  sentenceWithoutTranslation: Scalars['String'];
  errorType: TranslationInaccuracyError;
  translationId: Scalars['String'];
};

export enum TranslationInaccuracyError {
  Misspelling = 'misspelling',
  Grammar = 'grammar',
  Context = 'context',
}

export type TranslationProgression = {
  __typename?: 'TranslationProgression';
  viewCount: Scalars['Int'];
  lastSeenProgressionScore: Scalars['Int'];
};

export type TranslationReplacement = {
  __typename?: 'TranslationReplacement';
  index?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  sourcePhrase: Scalars['String'];
  translationId: Scalars['String'];
  isSavedWord?: Maybe<Scalars['Boolean']>;
  matchStatus?: Maybe<MatchStatus>;
  targetLanguage: Scalars['String'];
  difficultyLevel?: Maybe<Scalars['String']>;
  isActiveLearningShortcutPhrase?: Maybe<Scalars['Boolean']>;
};

export type TranslationsSeen = {
  __typename?: 'TranslationsSeen';
  amountSeen: Scalars['Int'];
  hasSeenTranslation: Scalars['Boolean'];
};

export type UserLearningShortcutCelebrationInput = {
  learningShortcutId: Scalars['String'];
};

export type UserLearningShortcutInput = {
  learningShortcutId: Scalars['String'];
};

export type UserLearningShortcutTopicInput = {
  learningShortcutTopicId: Scalars['String'];
};

export type UserReferral = {
  __typename?: 'UserReferral';
  userId: Scalars['ID'];
  redeemCode: Scalars['String'];
  linkUrl: Scalars['String'];
  sharedTo?: Maybe<Array<EmailShareInfo>>;
  rewardInfo: RewardInfo;
};

export type UserTranslation = {
  __typename?: 'UserTranslation';
  variationId: Scalars['String'];
  sourceRoot: Scalars['String'];
  isSaved: Scalars['Boolean'];
  isKnown: Scalars['Boolean'];
  progressionScore: Scalars['Int'];
  lastSeenProgressionScore: Scalars['Int'];
  viewCount: Scalars['Int'];
  hoverCount?: Maybe<Scalars['Int']>;
  lastPracticedAt?: Maybe<Scalars['String']>;
};

export type UserTranslationInput = {
  refreshProgression?: Maybe<Scalars['Boolean']>;
};

export type Vocab = {
  __typename?: 'Vocab';
  id: Scalars['String'];
  partOfSpeech?: Maybe<Scalars['String']>;
  isDefaultPartOfSpeech: Scalars['String'];
  commonTranslation?: Maybe<Translation>;
  collocations: Array<Translation>;
};

export type WebPageVisitInput = {
  url: Scalars['String'];
};

export type WebsiteUser = {
  __typename?: 'WebsiteUser';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type WhatYouKnow = {
  __typename?: 'WhatYouKnow';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Word = {
  __typename?: 'Word';
  word: Scalars['String'];
  language: Scalars['String'];
  lexicon: Array<Vocab>;
};

export type WordListInput = {
  id: Scalars['String'];
  wordListType?: Maybe<WordListType>;
};

export type WordListScreen = {
  __typename?: 'WordListScreen';
  amount: Scalars['Int'];
  translations: Array<ProgressionTranslation>;
};

export type WordListScreenInput = {
  type: ProgressionType;
  period: ProgressionPeriod;
  sort?: Maybe<WordListSort>;
};

export enum WordListSort {
  LastSeen = 'LastSeen',
  MostSeen = 'MostSeen',
  LeastSeen = 'LeastSeen',
  LastPracticed = 'LastPracticed',
  FirstPracticed = 'FirstPracticed',
  LastLearned = 'LastLearned',
  FirstLearned = 'FirstLearned',
  LeastToMostSeen = 'LeastToMostSeen',
  MostToLeastSeen = 'MostToLeastSeen',
  RecentlyAdded = 'RecentlyAdded',
}

export enum WordListType {
  Default = 'Default',
}

export type WordTranslation = {
  __typename?: 'WordTranslation';
  id: Scalars['String'];
  word: Scalars['String'];
  language: Scalars['String'];
  additionalInfo?: Maybe<AdditionalInfo>;
  localTranslationProgressionInfo?: Maybe<TranslationProgression>;
  translation: Scalars['String'];
  partOfSpeech?: Maybe<Scalars['String']>;
};

export type UninstallExtensionMutationVariables = Exact<{ [key: string]: never }>;

export type UninstallExtensionMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'uninstallExtension'>;

export type GetDictionaryQueryVariables = Exact<{ [key: string]: never }>;

export type GetDictionaryQuery = { __typename?: 'Query' } & {
  me?: Maybe<{ __typename?: 'Me' } & Pick<Me, 'selectedLanguage'>>;
  dictionary?: Maybe<{ __typename?: 'Dictionary' } & Pick<Dictionary, 'language' | 'size' | 'isSameLanguage'>>;
};

export type ForceDatasetDownloadMutationVariables = Exact<{ [key: string]: never }>;

export type ForceDatasetDownloadMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'forceDatasetDownload'>;

export type ForceOnboardingDatasetDownloadMutationVariables = Exact<{ [key: string]: never }>;

export type ForceOnboardingDatasetDownloadMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'forceOnboardingDatasetDownload'
>;

export type ClearExtensionWordCacheMutationVariables = Exact<{
  word: Scalars['String'];
}>;

export type ClearExtensionWordCacheMutation = { __typename?: 'Mutation' } & {
  clearWordCache: { __typename?: 'Word' } & Pick<Word, 'word'>;
};

export type OpenSidebarMutationVariables = Exact<{
  tabId?: Maybe<Scalars['Int']>;
  overlayName?: Maybe<Scalars['String']>;
  slideName?: Maybe<Scalars['String']>;
  panelName?: Maybe<Scalars['String']>;
  hydration?: Maybe<Scalars['String']>;
}>;

export type OpenSidebarMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'openSettings'>;

export type IsInjectedQueryVariables = Exact<{ [key: string]: never }>;

export type IsInjectedQuery = { __typename?: 'Query' } & {
  browser?: Maybe<{ __typename?: 'Browser' } & { activeTab: { __typename?: 'Tab' } & Pick<Tab, 'isToucanInjected'> }>;
};

export type LookupWordQueryVariables = Exact<{
  input: TranslationIdLookup;
}>;

export type LookupWordQuery = { __typename?: 'Query' } & Pick<Query, 'translationId'>;

export type LookupOnboardingWordQueryVariables = Exact<{
  language: TranslationIdLookup;
  context: TranslationIdLookup;
}>;

export type LookupOnboardingWordQuery = { __typename?: 'Query' } & {
  language: Query['translationId'];
  context: Query['translationId'];
};

export type GetExtensionInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetExtensionInfoQuery = { __typename?: 'Query' } & {
  browser?: Maybe<
    { __typename?: 'Browser' } & Pick<Browser, 'permissions'> & {
        manifest?: Maybe<{ __typename?: 'Manifest' } & Pick<Manifest, 'name' | 'version'>>;
      }
  >;
};

export type GetPermissionsQueryVariables = Exact<{
  domain: Scalars['String'];
}>;

export type GetPermissionsQuery = { __typename?: 'Query' } & {
  browser?: Maybe<{ __typename?: 'Browser' } & Pick<Browser, 'permissions'>>;
};

export type InjectIntoPageMutationVariables = Exact<{ [key: string]: never }>;

export type InjectIntoPageMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'injectActiveTab'>;

export type RequestGlobalPermissionsFromSiteMutationVariables = Exact<{ [key: string]: never }>;

export type RequestGlobalPermissionsFromSiteMutation = { __typename?: 'Mutation' } & {
  requestPermission: { __typename?: 'Browser' } & Pick<Browser, 'permissions'>;
};

export type RequestSitePermissionsMutationVariables = Exact<{
  domain: Scalars['String'];
}>;

export type RequestSitePermissionsMutation = { __typename?: 'Mutation' } & {
  requestPermission: { __typename?: 'Browser' } & Pick<Browser, 'permissions'>;
};

export type UserReferralInfoQueryVariables = Exact<{ [key: string]: never }>;

export type UserReferralInfoQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'Me' } & {
      userReferral: { __typename?: 'UserReferral' } & {
        rewardInfo:
          | ({ __typename?: 'AccountCreditRewardInfo' } & Pick<AccountCreditRewardInfo, 'rewardType'> & {
                metadata: { __typename?: 'AccountCreditRewardMetadata' } & Pick<
                  AccountCreditRewardMetadata,
                  'amount' | 'currencyCode'
                >;
              })
          | ({ __typename?: 'SchoolDonationRewardInfo' } & Pick<SchoolDonationRewardInfo, 'rewardType'> & {
                metadata: { __typename?: 'SchoolDonationRewardMetadata' } & Pick<
                  SchoolDonationRewardMetadata,
                  'schoolName' | 'schoolId'
                >;
              });
      };
    }
  >;
};

export type GetExtensionSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetExtensionSettingsQuery = { __typename?: 'Query' } & {
  settings: { __typename?: 'Settings' } & Pick<
    Settings,
    | 'highlightToTranslateEnabled'
    | 'isPronunciationEnabled'
    | 'isDailyGoalsModalEnabled'
    | 'isAllowingTrackingAnalytics'
  >;
  highlightToTranslate: { __typename?: 'HighlightToTranslateInfo' } & Pick<
    HighlightToTranslateInfo,
    'showWarning' | 'freeUsesRemaining'
  >;
  dictionary?: Maybe<{ __typename?: 'Dictionary' } & Pick<Dictionary, 'hasPronunciations'>>;
};

export type IsSnoozedForNewLanguageQueryVariables = Exact<{ [key: string]: never }>;

export type IsSnoozedForNewLanguageQuery = { __typename?: 'Query' } & {
  settings: { __typename?: 'Settings' } & Pick<Settings, 'isSnoozedForNewLanguage'>;
};

export type ChangeGloballyEnabledStateMutationVariables = Exact<{
  enabled: Scalars['Boolean'];
}>;

export type ChangeGloballyEnabledStateMutation = { __typename?: 'Mutation' } & {
  changeGloballyEnabledState: { __typename?: 'Settings' } & Pick<Settings, 'isGloballyEnabled'>;
};

export type ChangeGloballyDisabledDurationMutationVariables = Exact<{
  duration: SnoozeDuration;
}>;

export type ChangeGloballyDisabledDurationMutation = { __typename?: 'Mutation' } & {
  changeGloballyDisabledDuration: { __typename?: 'Settings' } & Pick<Settings, 'disabledDuration'>;
};

export type SetComingSoonLanguageMutationVariables = Exact<{
  languageId?: Maybe<ComingSoonLanguageId>;
}>;

export type SetComingSoonLanguageMutation = { __typename?: 'Mutation' } & {
  setComingSoonLanguage: { __typename?: 'Settings' } & Pick<Settings, 'selectedComingSoonLanguageId'>;
};

export type ChangeHighlightToTranslatedEnabledStateMutationVariables = Exact<{
  enabled: Scalars['Boolean'];
}>;

export type ChangeHighlightToTranslatedEnabledStateMutation = { __typename?: 'Mutation' } & {
  changeHighlightToTranslatedEnabledState: { __typename?: 'Settings' } & Pick<Settings, 'highlightToTranslateEnabled'>;
};

export type ChangePronunciationSettingsMutationVariables = Exact<{
  enabled: Scalars['Boolean'];
}>;

export type ChangePronunciationSettingsMutation = { __typename?: 'Mutation' } & {
  changePronunciationEnabledState: { __typename?: 'Settings' } & Pick<Settings, 'isPronunciationEnabled'>;
};

export type ChangeAnalyticsTrackingPreferenceMutationVariables = Exact<{
  isOptedIn: Scalars['Boolean'];
}>;

export type ChangeAnalyticsTrackingPreferenceMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'changeAnalyticsTrackingPreference'
>;

export type OpenSettingsMutationVariables = Exact<{
  tabId?: Maybe<Scalars['Int']>;
  overlayName?: Maybe<Scalars['String']>;
  slideName?: Maybe<Scalars['String']>;
  panelName?: Maybe<Scalars['String']>;
}>;

export type OpenSettingsMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'openSettings'>;

export type ChangeDailyGoalsEnabledStateMutationVariables = Exact<{
  enabled: Scalars['Boolean'];
}>;

export type ChangeDailyGoalsEnabledStateMutation = { __typename?: 'Mutation' } & {
  changeDailyGoalsEnabledState: { __typename?: 'Settings' } & Pick<Settings, 'isDailyGoalsModalEnabled'>;
};

export type GetSubscriptionInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubscriptionInfoQuery = { __typename?: 'Query' } & {
  me?: Maybe<{ __typename?: 'Me' } & Pick<Me, 'isPremiumSubscribed' | 'appleReceipt'>>;
};

export type RequestSafariInAppPaymentsMutationVariables = Exact<{
  input: RequestSafariInAppPaymentsInput;
}>;

export type RequestSafariInAppPaymentsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'requestSafariInAppPayments'
>;

export type MarkHasLearnedAboutPremiumAsSeenMutationVariables = Exact<{ [key: string]: never }>;

export type MarkHasLearnedAboutPremiumAsSeenMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'markHasLearnedAboutPremiumAsSeen'
>;

export type PerformPostSubscriptionActionsMutationVariables = Exact<{ [key: string]: never }>;

export type PerformPostSubscriptionActionsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'performPostSubscriptionActions'
>;

export type GetExtensionUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetExtensionUserQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'Me' } & Pick<
      Me,
      'id' | 'name' | 'email' | 'isPremiumSubscribed' | 'sourceLanguage' | 'selectedLanguage'
    >
  >;
};

export type RequestAuthorizationMutationVariables = Exact<{ [key: string]: never }>;

export type RequestAuthorizationMutation = { __typename?: 'Mutation' } & {
  requestAuthorization: { __typename?: 'Auth' } & Pick<Auth, 'token'>;
};

export type LogOutOfExtensionMutationVariables = Exact<{ [key: string]: never }>;

export type LogOutOfExtensionMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'logoutUserOnExtension'>;

export type AuthenticateUserMutationVariables = Exact<{
  input: AuthenticateUserInput;
}>;

export type AuthenticateUserMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'authenticateUser'>;

export type GetOnboardingChecklistPercentageQueryVariables = Exact<{ [key: string]: never }>;

export type GetOnboardingChecklistPercentageQuery = { __typename?: 'Query' } & {
  onboarding: { __typename?: 'Onboarding' } & {
    checklist: { __typename?: 'Checklist' } & Pick<Checklist, 'amountCompleted'>;
  };
};

export type WelcomeUserMutationVariables = Exact<{
  flow: AuthFlow;
}>;

export type WelcomeUserMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'welcomeUser'>;

export const UninstallExtensionDocument = gql`
  mutation uninstallExtension {
    uninstallExtension
  }
`;
export const useUninstallExtensionMutation = () =>
  useMutation<UninstallExtensionMutation, UninstallExtensionMutationVariables>(UninstallExtensionDocument);
export const GetDictionaryDocument = gql`
  query getDictionary {
    me(noCache: true) {
      selectedLanguage
    }
    dictionary {
      language
      size
      isSameLanguage
    }
  }
`;
export const useGetDictionaryQuery = (config?: { variables?: GetDictionaryQueryVariables; skip?: Boolean }) =>
  useQuery<GetDictionaryQuery, GetDictionaryQueryVariables>(GetDictionaryDocument, config);
export const ForceDatasetDownloadDocument = gql`
  mutation forceDatasetDownload {
    forceDatasetDownload
  }
`;
export const useForceDatasetDownloadMutation = () =>
  useMutation<ForceDatasetDownloadMutation, ForceDatasetDownloadMutationVariables>(ForceDatasetDownloadDocument);
export const ForceOnboardingDatasetDownloadDocument = gql`
  mutation forceOnboardingDatasetDownload {
    forceOnboardingDatasetDownload
  }
`;
export const useForceOnboardingDatasetDownloadMutation = () =>
  useMutation<ForceOnboardingDatasetDownloadMutation, ForceOnboardingDatasetDownloadMutationVariables>(
    ForceOnboardingDatasetDownloadDocument,
  );
export const ClearExtensionWordCacheDocument = gql`
  mutation clearExtensionWordCache($word: String!) {
    clearWordCache(word: $word) {
      word
    }
  }
`;
export const useClearExtensionWordCacheMutation = () =>
  useMutation<ClearExtensionWordCacheMutation, ClearExtensionWordCacheMutationVariables>(
    ClearExtensionWordCacheDocument,
  );
export const OpenSidebarDocument = gql`
  mutation openSidebar($tabId: Int, $overlayName: String, $slideName: String, $panelName: String, $hydration: String) {
    openSettings(
      tabId: $tabId
      overlayName: $overlayName
      slideName: $slideName
      panelName: $panelName
      hydration: $hydration
    )
  }
`;
export const useOpenSidebarMutation = () =>
  useMutation<OpenSidebarMutation, OpenSidebarMutationVariables>(OpenSidebarDocument);
export const IsInjectedDocument = gql`
  query isInjected {
    browser {
      activeTab {
        isToucanInjected
      }
    }
  }
`;
export const useIsInjectedQuery = (config?: { variables?: IsInjectedQueryVariables; skip?: Boolean }) =>
  useQuery<IsInjectedQuery, IsInjectedQueryVariables>(IsInjectedDocument, config);
export const LookupWordDocument = gql`
  query lookupWord($input: TranslationIdLookup!) {
    translationId(input: $input)
  }
`;
export const useLookupWordQuery = (config?: { variables?: LookupWordQueryVariables; skip?: Boolean }) =>
  useQuery<LookupWordQuery, LookupWordQueryVariables>(LookupWordDocument, config);
export const LookupOnboardingWordDocument = gql`
  query lookupOnboardingWord($language: TranslationIdLookup!, $context: TranslationIdLookup!) {
    language: translationId(input: $language)
    context: translationId(input: $context)
  }
`;
export const useLookupOnboardingWordQuery = (config?: {
  variables?: LookupOnboardingWordQueryVariables;
  skip?: Boolean;
}) => useQuery<LookupOnboardingWordQuery, LookupOnboardingWordQueryVariables>(LookupOnboardingWordDocument, config);
export const GetExtensionInfoDocument = gql`
  query getExtensionInfo {
    browser {
      permissions(permissions: { origins: ["*://*/*"] })
      manifest {
        name
        version
      }
    }
  }
`;
export const useGetExtensionInfoQuery = (config?: { variables?: GetExtensionInfoQueryVariables; skip?: Boolean }) =>
  useQuery<GetExtensionInfoQuery, GetExtensionInfoQueryVariables>(GetExtensionInfoDocument, config);
export const GetPermissionsDocument = gql`
  query getPermissions($domain: String!) {
    browser {
      permissions(permissions: { origins: [$domain] })
    }
  }
`;
export const useGetPermissionsQuery = (config?: { variables?: GetPermissionsQueryVariables; skip?: Boolean }) =>
  useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, config);
export const InjectIntoPageDocument = gql`
  mutation injectIntoPage {
    injectActiveTab
  }
`;
export const useInjectIntoPageMutation = () =>
  useMutation<InjectIntoPageMutation, InjectIntoPageMutationVariables>(InjectIntoPageDocument);
export const RequestGlobalPermissionsFromSiteDocument = gql`
  mutation requestGlobalPermissionsFromSite {
    requestPermission(permissions: { origins: ["*://*/*"] }) {
      permissions(permissions: { origins: ["*://*/*"] })
    }
  }
`;
export const useRequestGlobalPermissionsFromSiteMutation = () =>
  useMutation<RequestGlobalPermissionsFromSiteMutation, RequestGlobalPermissionsFromSiteMutationVariables>(
    RequestGlobalPermissionsFromSiteDocument,
  );
export const RequestSitePermissionsDocument = gql`
  mutation requestSitePermissions($domain: String!) {
    requestPermission(permissions: { origins: [$domain] }) {
      permissions(permissions: { origins: [$domain] })
    }
  }
`;
export const useRequestSitePermissionsMutation = () =>
  useMutation<RequestSitePermissionsMutation, RequestSitePermissionsMutationVariables>(RequestSitePermissionsDocument);
export const UserReferralInfoDocument = gql`
  query userReferralInfo {
    me {
      userReferral {
        rewardInfo {
          rewardType
          ... on AccountCreditRewardInfo {
            metadata {
              amount
              currencyCode
            }
          }
          ... on SchoolDonationRewardInfo {
            metadata {
              schoolName
              schoolId
            }
          }
        }
      }
    }
  }
`;
export const useUserReferralInfoQuery = (config?: { variables?: UserReferralInfoQueryVariables; skip?: Boolean }) =>
  useQuery<UserReferralInfoQuery, UserReferralInfoQueryVariables>(UserReferralInfoDocument, config);
export const GetExtensionSettingsDocument = gql`
  query getExtensionSettings {
    settings {
      highlightToTranslateEnabled
      isPronunciationEnabled
      isDailyGoalsModalEnabled
      isAllowingTrackingAnalytics
    }
    highlightToTranslate {
      showWarning
      freeUsesRemaining
    }
    dictionary {
      hasPronunciations
    }
  }
`;
export const useGetExtensionSettingsQuery = (config?: {
  variables?: GetExtensionSettingsQueryVariables;
  skip?: Boolean;
}) => useQuery<GetExtensionSettingsQuery, GetExtensionSettingsQueryVariables>(GetExtensionSettingsDocument, config);
export const IsSnoozedForNewLanguageDocument = gql`
  query isSnoozedForNewLanguage {
    settings {
      isSnoozedForNewLanguage
    }
  }
`;
export const useIsSnoozedForNewLanguageQuery = (config?: {
  variables?: IsSnoozedForNewLanguageQueryVariables;
  skip?: Boolean;
}) =>
  useQuery<IsSnoozedForNewLanguageQuery, IsSnoozedForNewLanguageQueryVariables>(
    IsSnoozedForNewLanguageDocument,
    config,
  );
export const ChangeGloballyEnabledStateDocument = gql`
  mutation changeGloballyEnabledState($enabled: Boolean!) {
    changeGloballyEnabledState(enabled: $enabled) {
      isGloballyEnabled
    }
  }
`;
export const useChangeGloballyEnabledStateMutation = () =>
  useMutation<ChangeGloballyEnabledStateMutation, ChangeGloballyEnabledStateMutationVariables>(
    ChangeGloballyEnabledStateDocument,
  );
export const ChangeGloballyDisabledDurationDocument = gql`
  mutation changeGloballyDisabledDuration($duration: SnoozeDuration!) {
    changeGloballyDisabledDuration(duration: $duration) {
      disabledDuration
    }
  }
`;
export const useChangeGloballyDisabledDurationMutation = () =>
  useMutation<ChangeGloballyDisabledDurationMutation, ChangeGloballyDisabledDurationMutationVariables>(
    ChangeGloballyDisabledDurationDocument,
  );
export const SetComingSoonLanguageDocument = gql`
  mutation setComingSoonLanguage($languageId: ComingSoonLanguageId) {
    setComingSoonLanguage(languageId: $languageId) {
      selectedComingSoonLanguageId
    }
  }
`;
export const useSetComingSoonLanguageMutation = () =>
  useMutation<SetComingSoonLanguageMutation, SetComingSoonLanguageMutationVariables>(SetComingSoonLanguageDocument);
export const ChangeHighlightToTranslatedEnabledStateDocument = gql`
  mutation changeHighlightToTranslatedEnabledState($enabled: Boolean!) {
    changeHighlightToTranslatedEnabledState(enabled: $enabled) {
      highlightToTranslateEnabled
    }
  }
`;
export const useChangeHighlightToTranslatedEnabledStateMutation = () =>
  useMutation<
    ChangeHighlightToTranslatedEnabledStateMutation,
    ChangeHighlightToTranslatedEnabledStateMutationVariables
  >(ChangeHighlightToTranslatedEnabledStateDocument);
export const ChangePronunciationSettingsDocument = gql`
  mutation changePronunciationSettings($enabled: Boolean!) {
    changePronunciationEnabledState(enabled: $enabled) {
      isPronunciationEnabled
    }
  }
`;
export const useChangePronunciationSettingsMutation = () =>
  useMutation<ChangePronunciationSettingsMutation, ChangePronunciationSettingsMutationVariables>(
    ChangePronunciationSettingsDocument,
  );
export const ChangeAnalyticsTrackingPreferenceDocument = gql`
  mutation changeAnalyticsTrackingPreference($isOptedIn: Boolean!) {
    changeAnalyticsTrackingPreference(isOptedIn: $isOptedIn)
  }
`;
export const useChangeAnalyticsTrackingPreferenceMutation = () =>
  useMutation<ChangeAnalyticsTrackingPreferenceMutation, ChangeAnalyticsTrackingPreferenceMutationVariables>(
    ChangeAnalyticsTrackingPreferenceDocument,
  );
export const OpenSettingsDocument = gql`
  mutation openSettings($tabId: Int, $overlayName: String, $slideName: String, $panelName: String) {
    openSettings(tabId: $tabId, overlayName: $overlayName, slideName: $slideName, panelName: $panelName)
  }
`;
export const useOpenSettingsMutation = () =>
  useMutation<OpenSettingsMutation, OpenSettingsMutationVariables>(OpenSettingsDocument);
export const ChangeDailyGoalsEnabledStateDocument = gql`
  mutation changeDailyGoalsEnabledState($enabled: Boolean!) {
    changeDailyGoalsEnabledState(enabled: $enabled) {
      isDailyGoalsModalEnabled
    }
  }
`;
export const useChangeDailyGoalsEnabledStateMutation = () =>
  useMutation<ChangeDailyGoalsEnabledStateMutation, ChangeDailyGoalsEnabledStateMutationVariables>(
    ChangeDailyGoalsEnabledStateDocument,
  );
export const GetSubscriptionInfoDocument = gql`
  query getSubscriptionInfo {
    me {
      isPremiumSubscribed
      appleReceipt
    }
  }
`;
export const useGetSubscriptionInfoQuery = (config?: {
  variables?: GetSubscriptionInfoQueryVariables;
  skip?: Boolean;
}) => useQuery<GetSubscriptionInfoQuery, GetSubscriptionInfoQueryVariables>(GetSubscriptionInfoDocument, config);
export const RequestSafariInAppPaymentsDocument = gql`
  mutation requestSafariInAppPayments($input: RequestSafariInAppPaymentsInput!) {
    requestSafariInAppPayments(input: $input)
  }
`;
export const useRequestSafariInAppPaymentsMutation = () =>
  useMutation<RequestSafariInAppPaymentsMutation, RequestSafariInAppPaymentsMutationVariables>(
    RequestSafariInAppPaymentsDocument,
  );
export const MarkHasLearnedAboutPremiumAsSeenDocument = gql`
  mutation markHasLearnedAboutPremiumAsSeen {
    markHasLearnedAboutPremiumAsSeen
  }
`;
export const useMarkHasLearnedAboutPremiumAsSeenMutation = () =>
  useMutation<MarkHasLearnedAboutPremiumAsSeenMutation, MarkHasLearnedAboutPremiumAsSeenMutationVariables>(
    MarkHasLearnedAboutPremiumAsSeenDocument,
  );
export const PerformPostSubscriptionActionsDocument = gql`
  mutation performPostSubscriptionActions {
    performPostSubscriptionActions
  }
`;
export const usePerformPostSubscriptionActionsMutation = () =>
  useMutation<PerformPostSubscriptionActionsMutation, PerformPostSubscriptionActionsMutationVariables>(
    PerformPostSubscriptionActionsDocument,
  );
export const GetExtensionUserDocument = gql`
  query getExtensionUser {
    me(noCache: true) {
      id
      name
      email
      isPremiumSubscribed
      sourceLanguage
      selectedLanguage
    }
  }
`;
export const useGetExtensionUserQuery = (config?: { variables?: GetExtensionUserQueryVariables; skip?: Boolean }) =>
  useQuery<GetExtensionUserQuery, GetExtensionUserQueryVariables>(GetExtensionUserDocument, config);
export const RequestAuthorizationDocument = gql`
  mutation requestAuthorization {
    requestAuthorization {
      token
    }
  }
`;
export const useRequestAuthorizationMutation = () =>
  useMutation<RequestAuthorizationMutation, RequestAuthorizationMutationVariables>(RequestAuthorizationDocument);
export const LogOutOfExtensionDocument = gql`
  mutation logOutOfExtension {
    logoutUserOnExtension
  }
`;
export const useLogOutOfExtensionMutation = () =>
  useMutation<LogOutOfExtensionMutation, LogOutOfExtensionMutationVariables>(LogOutOfExtensionDocument);
export const AuthenticateUserDocument = gql`
  mutation authenticateUser($input: AuthenticateUserInput!) {
    authenticateUser(input: $input)
  }
`;
export const useAuthenticateUserMutation = () =>
  useMutation<AuthenticateUserMutation, AuthenticateUserMutationVariables>(AuthenticateUserDocument);
export const GetOnboardingChecklistPercentageDocument = gql`
  query getOnboardingChecklistPercentage {
    onboarding {
      checklist {
        amountCompleted
      }
    }
  }
`;
export const useGetOnboardingChecklistPercentageQuery = (config?: {
  variables?: GetOnboardingChecklistPercentageQueryVariables;
  skip?: Boolean;
}) =>
  useQuery<GetOnboardingChecklistPercentageQuery, GetOnboardingChecklistPercentageQueryVariables>(
    GetOnboardingChecklistPercentageDocument,
    config,
  );
export const WelcomeUserDocument = gql`
  mutation welcomeUser($flow: AuthFlow!) {
    welcomeUser(flow: $flow)
  }
`;
export const useWelcomeUserMutation = () =>
  useMutation<WelcomeUserMutation, WelcomeUserMutationVariables>(WelcomeUserDocument);
